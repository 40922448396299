import { makeStyles } from "@mui/styles";
import color from "@definitions/mui/color";

export const useStyles = makeStyles(() => ({
  root: {
    zIndex: 1302
  },
  dialogHeader: {
    minHeight: "3.25rem",
    padding: "8px 16px",
    position: "relative",
  },
  closeButton: {
    position: "absolute",
    right: 12,
    zIndex: 1
  },
  dialogBody: {
    padding: "0 24px 24px",
  },
  drawer: {
    borderRadius: "20px 20px 0px 0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 16px 30px 16px",
  },
  puller: {
    width: 50,
    height: 5,
    backgroundColor: color.general.light,
    borderRadius: 3,
    marginBottom: 12,
    marginTop: 8,
  }
}));

export default useStyles;