import { useDispatch, useSelector } from "react-redux";
import { setClear } from "@redux/slices/warning";
import { RootState } from "@redux/reducers";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import color from "@definitions/mui/color";
import CloseIcon from "@icons/Close";
import Button from "../Button";
import Text from "../Text";
import useStyles from "./styles";
import useResponsive from "@helpers/useResponsive";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";

type TWarningActionButton = {
  label: string;
  variant: "primary" | "secondary" | "text";
  loading?: boolean;
  onClick?: () => void;
};

const Warning: React.FC = () => {
  const {
    warning,
    // loading
  } = useSelector((state: RootState) => ({
    warning: state.rootReducer.warning,
    loading: state.rootReducer.rootLoading,
  }));
  const dispatch = useDispatch();
  const classes = useStyles();
  const { messages, maxWidth, image, applyButton, open, additionalContent, ...rest } = warning;

  const smClient = useResponsive("sm");

  const _handleOnClose = () => {
    dispatch(setClear());
  };

  const hasApplyButton = applyButton?.find((elem: any) => elem.label);

  // const waitAction = applyButton
  //   ?.reduce((acc, item) => acc || loading[item.loader], false);

  const _renderImage = () => {
    const children = image;
    return (
      <Box mb={2} textAlign="center">
        {children}
      </Box>
    );
  };

  const _renderMessages = () => {
    return (
      <div>
        {messages?.map((message: string[], key: number) => {
          const child = message[0];
          const colorMUI = message[1] === "light" ? color.general.mid : color.general.main;
          const fontWeight = message[2] || "500";
          const isTitle = message[3] === "title";

          return (
            <Box key={key} mb={3}>
              <Text variant={isTitle ? "h2" : "body1"}>
                <Box
                  sx={{ color: colorMUI }}
                  fontSize={isTitle ? "24px" : "16px"}
                  fontWeight={fontWeight}
                  textAlign="center"
                >
                  {child}
                </Box>
              </Text>
            </Box>
          );
        })}
      </div>
    );
  };

  const _renderActionButton = () => {
    return (
      <>
        {applyButton?.map(
          ({ label, variant, loading, ...rest }: Partial<TWarningActionButton>, key: number) => {
            return (
              <Box mb={2} key={key}>
                <Button
                  // disabled={waitAction}
                  loading={loading}
                  size="large"
                  width="100%"
                  variant={variant}
                  {...rest}
                >
                  {label}
                </Button>
              </Box>
            );
          },
        )}
      </>
    );
  };

  const _renderAdditionalContent = () => {
    return (
      <Box mb={2} display="flex" justifyContent="center">{additionalContent}</Box>
    );
  };

  const renderContent = (
    <Box className={classes.dialogBody}>
      {_renderImage()}
      {_renderMessages()}
      {_renderAdditionalContent()}
      {hasApplyButton && _renderActionButton()}
    </Box>
  );

  const renderDialog = open && (
    <Dialog
      classes={{ root: classes.root }}
      open={true}
      fullWidth
      maxWidth={maxWidth}
      scroll="body"
      {...rest}
    >
      <Box className={classes.dialogHeader}>
        <IconButton className={classes.closeButton} onClick={_handleOnClose} size="large">
          <CloseIcon style={{ height: 16, width: 16 }} />
        </IconButton>
      </Box>
      {renderContent}
    </Dialog>
  );

  const renderDrawer = open && (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={_handleOnClose}
      onOpen={() => ({})}
      classes={{ paper: classes.drawer }}
      sx={{ zIndex: 1400 }}
    >
      <Box className={classes.puller} />
      <Box mt={3} sx={{ width: "100%" }}>
        {renderContent}
      </Box>
    </SwipeableDrawer>
  );

  return smClient ? renderDrawer : renderDialog;
};

export default Warning;
